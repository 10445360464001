import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Section } from '@/components/elements';

interface ComponentProps {
  title?: string;
  children: ReactNode;
  bgColor?: string;
  fullwidth?: boolean;
}

interface DivProps {
  bgColor: string;
}

const Header = styled.div<DivProps>`
  color: ${(props) =>
    props.bgColor === `primary`
      ? props.theme.palette.primary.contrastText
      : `inherit`};
`;
const Content = styled.div<DivProps>`
  color: ${(props) =>
    props.bgColor === `primary`
      ? props.theme.palette.primary.contrastText
      : `inherit`};
`;

const ContentSection: React.FC<ComponentProps> = ({
  title,
  children,
  bgColor,
  fullwidth,
}) => (
  <Section bgColor={bgColor} fullwidth={fullwidth}>
    {title && (
      <Header bgColor={bgColor}>
        <h2>{title}</h2>
      </Header>
    )}
    <Content bgColor={bgColor}>{children}</Content>
  </Section>
);

ContentSection.defaultProps = {
  title: null,
  bgColor: ``,
  fullwidth: false,
};

export default ContentSection;
