import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { Section } from '@/components/elements';

interface ComponentProps {
  title?: string;
  description?: string | ReactNode;
  items: any[];
  bgColor?: string;
  fullwidth?: boolean;
}

const Header = styled.div`
  margin-bottom: 2.5rem;
`;
const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
`;
const LinkItem = styled(Link)`
  color: ${(props) => props.theme.palette.primary.text};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 13rem;
  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;
const ItemCircleOutline = styled.div`
  border-radius: 50%;
  padding: 0.5rem;
  border: solid 2px;
  border-color: ${(props) => props.theme.palette.primary.main};
`;
const ItemCircle = styled.div`
  border-radius: 50%;
  height: 9rem;
  width: 9rem;
  border-color: ${(props) => props.theme.palette.primary.main};
  background-color: ${(props) => props.theme.palette.primary.main};
`;
const LinkItemTitle = styled.h4`
  margin-top: 1.5rem;
  font-weight: bold;
`;
const LinkItemText = styled.div`
  margin-top: 1rem;
`;
const LinkItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9rem;
  width: 9rem;
  & svg {
    width: 3rem;
  }
`;

const KeyPoints: React.FC<ComponentProps> = ({
  title,
  description,
  items,
  bgColor,
  fullwidth,
}) => (
  <Section bgColor={bgColor} fullwidth={fullwidth}>
    {title || description ? (
      <Header>
        {title && <h2>{title}</h2>}
        {description && <div>{description}</div>}
      </Header>
    ) : (
      ``
    )}
    <Content>
      {items.map((item, index) => (
        <LinkItem to={item.url} key={index}>
          <ItemCircleOutline>
            <ItemCircle>
              <LinkItemIcon>{item.icon}</LinkItemIcon>
            </ItemCircle>
          </ItemCircleOutline>

          {item.title && <LinkItemTitle>{item.title}</LinkItemTitle>}
          {item.text && <LinkItemText>{item.text}</LinkItemText>}
        </LinkItem>
      ))}
    </Content>
  </Section>
);

KeyPoints.defaultProps = {
  title: null,
  description: null,
  bgColor: ``,
  fullwidth: false,
  //  iconColor: `#59abdf`,
  //  paddingTop: true,
  //  paddingBottom: true,
};

export default KeyPoints;
